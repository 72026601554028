import api from "../http/index";

class Filiais {

    async gravarLogoEmpresa({photo}){
        const formData = new FormData();
    
        const file = photo;
        formData.append('account[logo]', file);
    
        let retorno = await api({
          method: "PATCH",
          url: `/account_admins/current_account/images`,
          data:formData
        });
    
        return retorno;
    };  

    async gravarBackgroundEmpresa({photo}){
        const formData = new FormData();
    
        const file = photo;
        formData.append('account[background]', file);
    
        let retorno = await api({
          method: "PATCH",
          url: `/account_admins/current_account/images`,
          data:formData,
        });
    
        return retorno;
    };  

    async gravarNovaFilial(dados) {
        
        let retorno = await api({
            method: "POST",
            url: "account_admins/headquarters",
            data: {
                headquarter:{
                    ...dados
                }
            },
        });
    
        return retorno;
    };

    async editarFilial(dados) {
        
        let retorno = await api({
            method: "PATCH",
            url: `account_admins/headquarters/${dados.id}`,
            data: {
                headquarter:{
                    ...dados
                }
                
            },
        });
    
        return retorno;
    };
    async obterFilialPorId(id) {
        
        let retorno = await api({
            method: "GET",
            url: `account_admins/headquarters/${id}?key_transform_camel_lower=true`,
        });
    
        return retorno;
    };
}
 
export default new Filiais()
