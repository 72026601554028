<template>
  <div style="display: contents">
    <b-form-input
      @input="executarFiltro"
      id="filter-input"
      v-model="filtroBusca"
      type="search"
      class="customInput text-left mb-2"
      placeholder="O que você está buscando?"
    ></b-form-input>

    <b-col
      v-if="dadosTabela && dadosTabela.length === 0"
      class="d-flex align-items-center justify-content-center mt-5 text-muted"
    >
      <p>
        Não há registros para exibir. Pressione
        <b style="cursor: pointer" @click="obterDadosTabela({})">Atualizar</b>
        para tentar novamente.
      </p>
    </b-col>

    <b-table
      :key="componentKey"
      v-if="dadosTabela && dadosTabela.length > 0"
      no-local-sorting
      hover
      ref="table"
      :striped="isMobile()"
      v-bind="tableProps"
      @row-clicked="selecionarLinha"
      @sort-changed="alterarOrdenacaoTabela"
      @row-dblclicked="selecionarLinhaDuploClique"
      @row-contextmenu="selecionouLinhaContexto"
    >
      <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
        <slot :name="slotName" v-bind="scope" />
      </template>
    </b-table>

    <b-row
      class="mt-4"
      v-if="dadosTabela && dadosTabela.length > 0 && !hidePagination"
    >
      <b-col cols="12">
        <b-pagination
          align="center"
          v-model="paginaAtual"
          :total-rows="totalRegistros"
          @change="
            (pageEvent = 1) =>
              obterDadosTabela({ filtro: filtroBusca, paginacao: pageEvent })
          "
          :per-page="itensPorPagina"
        ></b-pagination>
      </b-col>
      <b-col :class="'text-center'" cols="12">
        <p class="text-muted">
          Exibindo {{ (paginaAtual - 1) * itensPorPagina + 1 }} até
          {{
            paginaAtual * itensPorPagina -
            (itensPorPagina - totalRegistrosPaginaAtual)
          }}
          de
          {{ totalRegistros }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Mixin from "../core/Mixin";
import api from "../http/index";

export default {
  name: "CustomTable",
  mixins: [Mixin],
  data() {
    return {
      filtroBusca: "",
      totalRegistros: 0,
      dadosTabela: [],
      itensPorPagina: 0,
      paginaAtual: 1,
      totalPaginas: 0,
      totalRegistrosPaginaAtual: 0,
      componentKey: 1,
    };
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 1, // VERIFICAR, NAO ESTA ALTERANDO COMO DEVERIA.
    },
    axiosPathCount: {
      type: String,
      default: "",
    },
    axiosPathData: {
      type: String,
      default: "",
    },
    axiosKeyFilter: {
      type: String,
      default: "search",
    },
    optionsAxios: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    caption: String,
    responsive: Boolean,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    outlined: Boolean,
    small: Boolean,
    fixed: Boolean,
    footClone: Boolean,
    tbodyTrClass: [String, Function],
    theadTrClass: [String, Function],
    busy: Boolean,
    busyTemplate: String,
    stacked: String,
    stackedSize: String,
    headVariant: String,
    footVariant: String,
    captionTop: Boolean,
    sortIcon: String,
    sortIconSize: String,
    noCollapse: Boolean,
    tbodyTrAttr: [Object, Function],
    theadTrAttr: [Object, Function],
    busyContext: Object,
    checkAllRows: Boolean,
    dark: Boolean,
    hidePagination: Boolean,
    borderless: Boolean,
    filter: [String, RegExp],
  },
  computed: {
    tableProps() {
      return {
        items: this.dadosTabela,
        fields: this.fields,
        caption: this.caption,
        responsive: this.responsive,
        hover: this.hover,
        striped: this.striped,
        bordered: this.bordered,
        outlined: this.outlined,
        small: this.small,
        fixed: this.fixed,
        footClone: this.footClone,
        tbodyTrClass: this.tbodyTrClass,
        theadTrClass: this.theadTrClass,
        busy: this.busy,
        busyTemplate: this.busyTemplate,
        stacked: this.stacked,
        stackedSize: this.stackedSize,
        headVariant: this.headVariant,
        footVariant: this.footVariant,
        captionTop: this.captionTop,
        sortIcon: this.sortIcon,
        sortIconSize: this.sortIconSize,
        noCollapse: this.noCollapse,
        tbodyTrAttr: this.tbodyTrAttr,
        theadTrAttr: this.theadTrAttr,
        busyContext: this.busyContext,
        checkAllRows: this.checkAllRows,
        dark: this.dark,
        borderless: this.borderless,
        filter: this.filter,
        hidePagination: this.hidePagination,
      };
    },
  },

  mounted() {
    this.obterDadosTabela({});
  },

  methods: {
    atualizarComponentKey() {
      this.componentKey += 1;
    },

    async executarFiltro(filtro) {
      if (await this.debounce(300)) {
        this.obterDadosTabela({ filtro });
      }
    },

    async obterDadosTabela({ filtro = "", paginacao = 1, ordenarPor }) {
      try {
        this.setLoading(true);

        if (!this.optionsAxios) {
          let options = this.items;

          if (filtro != "") {
            options = this.items.filter((localOption) =>
              localOption["name"].toLowerCase().includes(filtro.toLowerCase())
            );
          }

          this.atualizarTabela(options);
          return;
        }

        this.optionsAxios.params = {
          ...this.optionsAxios.params,
          [this.axiosKeyFilter]: filtro,
          page: paginacao,
        };

        if (ordenarPor) {
          this.optionsAxios.params.sort_property = ordenarPor.chave;
          this.optionsAxios.params.sort_direction = ordenarPor.ordenacao;
        }

        let retorno = await api(this.optionsAxios);

        this.totalRegistros = this.lodash.get(retorno, this.axiosPathCount, 0);
        this.itensPorPagina = this.itemsPerPage;
        this.totalPaginas = Math.ceil(
          this.totalRegistros / this.itensPorPagina
        );
        this.totalRegistrosPaginaAtual = this.lodash.get(
          retorno,
          this.axiosPathData,
          []
        ).length;

        this.atualizarTabela(this.lodash.get(retorno, this.axiosPathData, []));
      } catch (e) {
        console.warn(`[obterOpcoesMultiselect] Detalhes: ${e}`);
        this.atualizarTabela([]);
      } finally {
        this.setLoading(false);
      }
    },

    atualizarTabela(opcoes) {
      this.dadosTabela = opcoes;

      if (!this.$refs.table) return;

      this.$refs.table.refresh();
      this.atualizarComponentKey();
    },

    selecionarLinha(item, index, event) {
      this.$emit("row-clicked", { item, index, event });
    },

    selecionarLinhaDuploClique(item, index, event) {
      this.$emit("row-clicked", { item, index, event });
    },

    selecionouLinhaContexto(item, index, event) {
      this.$emit("row-contextmenu", { item, index, event });
    },

    alterarOrdenacaoTabela(sortedItems) {
      if (this.optionsAxios) {
        this.obterDadosTabela({
          ordenarPor: {
            chave: this.camelCaseToSnakeCase(sortedItems.sortBy),
            ordenacao:
              this.lodash.get(
                this.optionsAxios,
                "params.sort_direction",
                "desc"
              ) == "desc"
                ? "asc"
                : "desc",
          },
        });
      }
    },

    camelCaseToSnakeCase(camelCase) {
      return camelCase.replace(/([A-Z])/g, "_$1").toLowerCase();
    },
  },
};
</script>

<style>
td {
  vertical-align: middle !important;
}

.input-group-filtros {
  background-color: var(--cor-cards) !important;
  padding: 4px 4px 4px 25px;
  border-radius: 7px !important;
}
/* Estilos personalizados, se necessário */
</style>
