<template>
  <b-col v-bind="propriedadesContainer" v-if="visible">
    <b-button
      :id="idGerado"
      v-bind="propriedadesBotao"
      @click="handleClick"
      @mouseover="handleMouseOver"
      @mouseout="handleMouseOut"
      :disabled="isLoading"
    >
      {{ buttonText }}
      <b-icon v-if="propriedadesIcone.icon" v-bind="propriedadesIcone"></b-icon>
      <i v-if="propriedadesIcone.class" v-bind="propriedadesIcone"></i>
    </b-button>

    <b-popover
      v-if="propriedadesHint.hasHint"
      :target="idGerado"
      triggers="hover"
      placement="top"
      custom-class="dark-background"
    >
      <template #title>
        <b class="fonte-customizada-dark">{{
          propriedadesHint.hintTitle
        }}</b></template
      >
      <b class="fonte-customizada-dark">{{ propriedadesHint.hintText }}</b>
    </b-popover>

    <b-modal
      v-model="showConfirmation"
      ref="modal-confirmar-acao-button"
      size="lg"
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      :hide-header="true"
      centered
    >
      <div class="p-5 d-block text-center">
        <p>{{ confirmationText }}</p>
      </div>

      <template #modal-footer>
        <b-button class="text-light" variant="primary" @click="cancelAction"
          >Voltar</b-button
        >
        <b-button variant="light" @click="confirmAction">Confirmar</b-button>
      </template>
    </b-modal>
  </b-col>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomButton",
  data() {
    return {
      showConfirmation: false,
      pendingAction: null,
      idGerado: new Date().getTime().toString(),
    };
  },
  props: {
    confirmationText: {
      type: String,
      default: "Confirme para realizar a ação.",
    },
    hasConfirmation: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    classButton: {
      type: String,
      default: "",
    },
    iconProps: {
      type: Object,
    },
    hintProps: {
      type: Object,
    },
    containerProps: {
      type: Object,
    },
    active: Boolean,
    block: Boolean,
    disabled: Boolean,
    href: String,
    size: String,
    variant: String,
    tag: {
      type: String,
      default: "button",
    },
    type: {
      type: String,
      default: "button",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    pressed: {
      type: [Boolean, String],
      default: false,
    },
    autocomplete: String,
    autofocus: Boolean,
    name: String,
    required: Boolean,
    form: String,
    tabindex: [String, Number],
    id: String,
    ariaPressed: {
      type: [Boolean, String],
      default: null,
    },
    ariaLabel: String,
    ariaControls: String,
    ariaExpanded: {
      type: [Boolean, String],
      default: null,
    },
    ariaLive: String,
    ariaOwns: String,
    buttonText: {
      type: String,
      default: "Click me",
    },
  },

  mounted() {},

  computed: {
    ...mapGetters(["isLoading"]),

    propriedadesBotao() {
      let propriedades = { ...this.lodash.get(this.$options, "propsData", {}) };

      let possuiContainer =
        Object.keys(this.lodash.get(propriedades, "containerProps", {}))
          .length > 0;

      delete propriedades?.iconProps;
      delete propriedades?.containerProps;

      return {
        ...propriedades,
        class: `botao-padrao ${
          possuiContainer ? "tamanho-botao-sem-container" : ""
        } ${this.classButton}`,
      };
    },

    propriedadesIcone() {
      return {
        ...this.lodash.get(this.$options, "propsData.iconProps", {}),
      };
    },

    propriedadesHint() {
      return {
        ...this.lodash.get(this.$options, "propsData.hintProps", {}),
      };
    },

    propriedadesContainer() {
      return {
        style:
          Object.keys(
            this.lodash.get(this.$options, "propsData.containerProps", {})
          ).length == 0
            ? "display:contents"
            : "",
        ...this.lodash.get(this.$options, "propsData.containerProps", {}),
      };
    },
  },
  methods: {
    handleClick() {
      if (this.hasConfirmation) {
        this.executeWithConfirmation();
      } else {
        this.$emit("button-click");
      }
    },

    handleMouseOver() {
      this.$emit("button-mouseover");
    },

    handleMouseOut() {
      this.$emit("button-mouseout");
    },

    async executeWithConfirmation() {
      this.showConfirmation = true;

      this.pendingAction = this.performAction; // Armazena a ação a ser executada
    },

    async confirmAction() {
      this.showConfirmation = false;
      if (this.pendingAction) {
        await this.pendingAction();
        this.pendingAction = null;
      }
    },

    cancelAction() {
      this.showConfirmation = false;
      this.pendingAction = null;
    },

    async performAction() {
      this.$emit("button-click");
      this.showConfirmation = false;
    },
  },
};
</script>

<style>
.tamanho-botao-sem-container {
  width: 100% !important;
}
.botao-padrao {
  min-width: 120px;
  margin: 5px;
  font-size: 1.15em;
}

.confirmation-dialog {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px 0;
  background-color: #f9f9f9;
}
/* Adicione estilos personalizados aqui, se necessário */
</style>
