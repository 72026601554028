import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_URL,
});

api.interceptors.request.use(
  async (config) => {
    let dadosUsuarioLogado = JSON.parse(localStorage.getItem("dadosUsuarioLogado"));

    if (dadosUsuarioLogado && dadosUsuarioLogado.length > 0) {
      dadosUsuarioLogado = dadosUsuarioLogado[0];
    }

    // Verifica se os dados do usuário logado estão presentes e atualiza os headers
    config.headers = {
      authkey: "f4f49fd6deed76678e60",
      "access-token": dadosUsuarioLogado?.accessToken ?? "",
      client: dadosUsuarioLogado?.client ?? "",
      uid: dadosUsuarioLogado?.uid ?? "",
      ...config.headers,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
