class UtilsComponentePagamento {

  obterInputsPix(){
    return [
      {
        label: "Código PIX",
        id: "payload",
        model: "payload",
        placeholder: "",
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        cols: 12,
        appendButton: true,
        isTextArea:true,
        inputType: "text",
        executeOnPressButtonGroup: `copiarParaAreaDeTransferencia`,
        iconAppendButton: "journals",
        buttonText:"Copiar",
        disabled:true,
      },
    ]
  }

  obterInputsCartaoCredito(){
      return [
          {
            label: "Nome impresso no cartao de crédito",
            id: "holderName",
            model: "holderName",
            xl: 6,
            cols: 12,
          },
          {
            label: "Número do cartao de crédito",
            id: "number",
            model: "number",
            xl: 6,
            cols: 12,
            inputType: "number",
            iconAppendButton: "credit-card-2-front",
            appendButton: true,
            isInputMask:true,
            maskOptions:[
              '####-####-####-####'
            ]
          },
          {
            label: "CCV",
            id: "ccv",
            model: "ccv",
            inputType: "number",
            xl: 4,
            cols: 12,
            iconAppendButton: "credit-card",
            appendButton: true,
            isInputMask:true,
            maskOptions:[
              '###'
            ]
          },
          {
            label: "Mês validade final",
            id: "expiryMonth",
            model: "expiryMonth",
            inputType: "number",
            xl: 4,
            cols: 12,
            isInputMask:true,
            maskOptions:[
              '##'
            ]
          },
          {
            label: "Ano validade final",
            id: "expiryYear",
            model: "expiryYear",
            inputType: "number",
            xl: 4,
            cols: 12,
            isInputMask:true,
            maskOptions:[
              '####'
            ]
          },
      ];
  }

  obterInputsTipoPagamento(){
      return [
          {
            label: "Selecione a forma de pagamento",
            id: "billingType",
            model: "billingType",
            isMultiselect: true,
            multiple: false,
            labelsMultiselect: "name",
            placeholder: "busque pela forma de pagamento",
            optionsAxios: {
              method: "GET",
              url: `/enumerations/billing_types?key_transform_camel_lower=true`,
              params: {
                sort_direction: "desc",
                active: true,
                profile_type: 1,
              },
            },
            axiosPathData: "data.billingTypes",
            axiosKeyFilter: "name",
          },
      ];
  }

  obterBotoesAcaoModalPagamento(){
      return [
          {
              containerProps:{
                  cols: '6',
                  xl: '6',
                  lg: '6',
                  md: '6',
                  sm: '6',
              },
              buttonText:"Cancelar",
              hasConfirmation:true,
              confirmationText:"Tem certeza que deseja cancelar o pagamento?",
              variant:"light",
              click:"this.fecharModal('componente-pagamentos-global')",
          },
      ]
  };

  obterBotoesAcaoPagamentoCartao(){
    return [
      {
        iconProps:{
            icon: 'cart-check',
            variant: 'primary',
            'font-scale': '1.4',
        },
        containerProps:{
            cols: '6',
            xl: '6',
            lg: '6',
            md: '6',
            sm: '6',
        },
        classButton:"text-light",
        buttonText:"Pagar",
        variant:"success",
        click:"this.enviarDadosPagamentoCartao()",
      }
  ]
  }
}
 
export default new UtilsComponentePagamento()
