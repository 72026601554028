import api from "../http/index";
import headquarters from "./headquarters";

class Pagamentos {

    async enviarPagamentoCartao(dados) {
        
        let retorno = await api({
            method: "POST",
            url: `billings/${dados.idCobranca}/pay`,
            data: {
                billing:{
                    ...dados
                }
            },
        });
    
        return retorno;
    };

    async gerarPagamentoPlano(dados) {
        
        let retorno = await api({
            method: "POST",
            url: `subscription_plans/${dados.idPlano}/generate_billing`,
            data: {
                subscriptionPlan:{
                    ...dados
                }
            },
        });
    
        return retorno;
    };

    async obterCobrancaPorId(id){

        let retorno = await api({
            method: "GET",
            url: `/billings/${id}?key_transform_camel_lower=true`,
        });
    
        return retorno;
    };

    async cancelarCobrancaPorId({idCobranca, headquarterId}){

        return  await api({
          method: 'DELETE',
          url: `/billings/${idCobranca}`,
          data:{
            billing:{
                headquarterId: headquarterId
            }
          }

        });
    
    };

    async gerarPagamentoAtendimentoFila(dados) {
        let id = dados.idAtendimentoFila;
        delete dados.idAtendimentoFila;

        let retorno = await api({
            method: "POST",
            url: `customer_queues/${id}/generate_billing`,
            data: {
                customerQueue:{
                    ...dados
                }
            },
        });
    
        return retorno;
    };

    async gerarPagamentoAtendimentoAgendado(dados) {
        let id = dados.idAtendimentoAgendado;
        delete dados.idAtendimentoAgendado;
        
        let retorno = await api({
            method: "POST",
            url: `schedules/${id}/generate_billing`,
            data: {
                schedule:{
                    ...dados
                }
            },
        });
    
        return retorno;
    };

}
 
export default new Pagamentos()
