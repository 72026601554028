<template>
  <div style="display: contents">
    <vue-navigation-bar
      :options="navbarOptions"
      @vnb-item-clicked="changeRoute"
    />

    <sidebar-menu
      :collapsed="true"
      :menu="menu"
      :widthCollapsed="!this.isMobile() ? '60px' : '49px'"
      :hideToggle="false"
      @toggle-collapse="onToggleCollapse"
      :rtl="false"
      theme="dark-theme"
    >
      <span style="font-size: 1.5em" slot="toggle-icon">
        <i v-if="!sidebarOpened" class="fa-solid fa-toggle-on mr-3"></i>
        <i v-if="sidebarOpened" class="fa-solid fa-toggle-off mr-3"></i>
      </span>

      <div v-if="!sidebarOpened" slot="footer" class="text-center">
        <ImageThumb
          :hasDetailsModal="false"
          :rounded="false"
          :altura="200"
          :thumbnail="false"
          :largura="300"
          :src="
            lodash.get(
              $dadosSessao,
              'user.account.logo',
              require('../assets/mb-solucoes.png')
            )
          "
        />
        <p class="service-details" style="font-size: 0.9em">
          Desenvolvido por <b>Skedoole</b>
        </p>
      </div>
    </sidebar-menu>
  </div>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  mixins: [Mixin],
  name: "Navbar",

  data: function () {
    return {
      sidebarOpened: true,
      menu: [],
      nomeUsuario: "",
      imagens: {},
      navbarOptions: {
        elementId: "main-navbar",
        class: "nk-header is-light",
        isUsingVueRouter: true,
        mobileBreakpoint: 50,
        brandImagePath: "Home",
        isUsingVueRouter: true,
        brandImage: "", //this.lodash.get(this.$dadosSessao, "user.account.logo", ""),
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "black",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Skedoole",
        tooltipAnimationType: "perspective",
        tooltipPlacement: "bottom",
        menuOptionsLeft: [],
        menuOptionsRight: [],
      },
    };
  },

  async mounted() {
    this.obterImagens();
    this.montarImagensEmpresa();
    this.obterNomeUsuarioLogado();
    this.obterMenusPorTipoUsuario();
    this.obterTopBarOptions();

    await this.$nextTick();
    this.updateUserToggleStyle();
  },

  methods: {
    updateUserToggleStyle() {
      let foto = this.lodash.get(this.$dadosSessao, "user.photo.small", "");

      if (foto != "") {
        const userToggle = document.querySelector(".user-avatar");
        userToggle.style.backgroundImage = `url(${foto})`;
        userToggle.style.backgroundSize = "cover";
        userToggle.style.backgroundPosition = "center";

        const userToggleIcon = document.querySelector(".ni-user-alt");
        userToggleIcon.style.color = "transparent";
      }
    },

    obterTopBarOptions() {
      this.navbarOptions.menuOptionsRight = [
        {
          type: "link",
          text: "",
          arrowColor: "#659CC8",
          iconRight: `
              <li class="dropdown user-dropdown">
                <a href="#" class="dropdown-toggle me-n1" data-bs-toggle="dropdown">
                    <div class="user-toggle">
                        <div  style="background-color:var(--cor-primaria)" class="user-avatar sm"><em class="icon ni ni-user-alt"></em></div>
                        <div class="user-info d-none d-xl-block">
                            <div class="user-name">${this.lodash.get(
                              this,
                              "$dadosSessao.user.name",
                              ""
                            )}</div>
                        </div>
                    </div>
                    
                </a>
              </li>
            `,
          subMenuOptions: [
            {
              isLinkAction: true,
              type: "link",
              text: "Meu Perfil",
              path: { name: "" },
              iconLeft:
                '<li><a href="/demo2/lms/admin-profile.html"><em class="icon ni ni-user-alt"></em><span></span></a></li>',
            },
            {
              type: "hr",
            },
            {
              isLinkAction: true,
              type: "link",
              text: "Planos",
              path: { name: "Listagem planos" },
              iconLeft:
                '<li><a href="/demo2/lms/admin-profile.html"><em class="icon ni ni-reports"></em><span></span></a></li>',
            },
            {
              type: "hr",
            },
            {
              isLinkAction: true,
              type: "link",
              text: "Sair",
              path: { name: "Login" },
              iconLeft:
                '<li><a href="#"><em class="icon ni ni-signout"></em></a></li>',
            },
          ],
        },
      ];
    },

    onToggleCollapse(collapsed) {
      this.sidebarOpened = collapsed;
    },

    obterNomeUsuarioLogado() {
      this.nomeUsuario = this.getItemsFromLocalStorage("dadosUsuarioLogado")[0];
    },

    obterImagens() {
      this.imagens = this.getItemsFromLocalStorage("imagensEmpresa")[0];
    },

    montarImagensEmpresa() {
      this.navbarOptions.brandImage = this.imagens?.logo ?? "";
    },

    changeRoute(route) {
      if (route == "Planos") {
        this.$router
          .push({
            name: "Listagem planos",
            params: {
              id: this.lodash.get(this, "$dadosSessao.user.id", ""),
            },
          })
          .catch((e) => {
            console.error(e);
          });
      }

      if (route == "Meu Perfil" && this.$route.name != "Cadastro Usuario") {
        this.$router
          .push({
            name: "Cadastro Usuario",
            params: {
              id: this.lodash.get(this, "$dadosSessao.user.id", ""),
            },
          })
          .catch((e) => {
            console.error(e);
          });
      }

      if (route == "Sair") {
        this.$router
          .push({
            path: `/login?empresa=${this.lodash.get(
              this.$store,
              "getters.getPropriedades.dadosUsuarioLogado.subdomain",
              this.lodash.get(
                this.getItemsFromLocalStorage("dadosUsuarioLogado"),
                "[0].subdomain",
                ""
              )
            )}`,
          })
          .catch(() => {
            this.$router.push({ path: "/estabelecimentos" });
          });
      }
    },

    obterMenusPorTipoUsuario() {
      let itensNavegacao = this.lodash.get(
        this,
        "$dadosSessao.user.homeTools",
        []
      );

      this.menu = [
        {
          header: "Main Navigation",
          hiddenOnCollapse: false,
        },
        {
          href: "/home",
          title: "Inicio",
          icon: {
            element: "i",
            class: "fa-sharp fa-solid fa-house",
          },
        },
      ];

      itensNavegacao.map((item) => {
        this.menu.push({
          href: item.slug,
          title: item.name,
          icon: item.icon,
        });
      });

      this.menu.push({
        href: `/login?empresa=${this.lodash.get(
          this.$store,
          "getters.getPropriedades.dadosUsuarioLogado.subdomain",
          this.lodash.get(
            this.getItemsFromLocalStorage("dadosUsuarioLogado"),
            "[0].subdomain",
            ""
          )
        )}`,
        title: "Sair",
        icon: {
          element: "i",
          class: "fa-solid fa-arrow-right-from-bracket",
        },
      });
    },
  },
};
</script>

<style lang="scss">
#main-navbar {
  margin-left: 40px;
}
</style>

