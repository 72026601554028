import axios from "axios";

export default async function validarSessao() {
  try{
    let sessaoValida = false;
    let dadosUsuarioLogado = localStorage.getItem("dadosUsuarioLogado");
    let dadosTratados = JSON.parse(dadosUsuarioLogado);
  
    sessaoValida = validarTempoSessao(dadosTratados);
  
    return sessaoValida;
  }catch(_){
    return false;
  }
};

function validarTempoSessao(dadosSessaoAtiva) {
  try{
    let horaFimSessao = parseInt(dadosSessaoAtiva.expiry) * 1000; // Convertendo para milissegundos
    let horaAtual = new Date().getTime(); // Obtendo o timestamp em milissegundos
    
    // if (horaFimSessao > horaAtual) {
      return true; 
    // }
  
    return false; 
  }catch(_){
    return false;
  }
  
};
