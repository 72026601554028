<template>
  <div sytle="display:contents">
    <!-- Quando é multiselect exibe o modal de opcoes -->
    <b-modal
      :scrollable="true"
      ref="modal-dinamico"
      size="xl"
      :title="propriedadesInput.placeholder"
      centered
      @hidden="fecharModal"
      hideFooter
      body-class="container-detalhes-modal-options"
      footer-class="container-detalhes-modal-options"
      header-class="container-detalhes-modal-options"
    >
      <b-row>
        <b-col class="mb-3 p-0" cols="12">
          <!-- <b-input-group size="xl"> -->
          <b-form-input
            @input="executarFiltroMultiselect"
            id="filter-input"
            v-model="filtroBuscaMultiselect"
            type="search"
            class="form-control rounded-1"
            placeholder="O que você está procurando?"
          ></b-form-input>

          <!-- <b-icon class="m-3" variant="light" icon="search"></b-icon>
          </b-input-group> -->
        </b-col>

        <b-table
          ref="tableOptionsMultiselect"
          borderless
          table-variant="dark"
          :items="opcoesMultiselect"
          :stacked="false"
          dark
          :fields="fields"
          @row-clicked="selecionarLinha"
        >
        </b-table>
      </b-row>
    </b-modal>

    <label v-if="!isSwitch" class="label-inputs d-flex justify-content-start"
      >{{ label }}
      <div v-if="propriedadesInput.hintText" class="ml-1">
        <Hint
          containerClass="d-contents"
          :hintText="propriedadesInput.hintText"
          :hintTitle="propriedadesInput.hintTitle"
          iconName="info-circle"
          :variant="'info'"
        />
      </div>
    </label>

    <b-form-group>
      <b-input-group>
        <b-form-input
          :placeholder="propriedadesInput.placeholder"
          id="password-input"
          v-if="
            !propriedadesInput.isSwitch &&
            !propriedadesInput.isMultiselect &&
            !propriedadesInput.isInputFile &&
            !propriedadesInput.isInputMask &&
            !propriedadesInput.isDatePicker &&
            !propriedadesInput.isTextArea &&
            !propriedadesInput.isInputCurrencyMask
          "
          :class="`form-control ${propriedadesInput.class}`"
          size="md"
          v-model="vmodel"
          @input="onChange"
          :state="state"
          :formatter="formatter"
          :type="inputType"
          autocomplete="off"
          :disabled="disabled"
        ></b-form-input>

        <b-form-textarea
          v-if="propriedadesInput.isTextArea"
          v-model="vmodel"
          rows="3"
          max-rows="9"
          :class="`form-control ${propriedadesInput.class}`"
          @input="onChange"
        ></b-form-textarea>

        <money
          v-if="propriedadesInput.isInputCurrencyMask"
          v-model="vmodel"
          v-bind="propriedadesInput.currencyMaskOptions"
          :class="`form-control ${propriedadesInput.class}`"
          @input="onChange"
        ></money>

        <the-mask
          v-if="propriedadesInput.isInputMask"
          :class="`form-control ${propriedadesInput.class}`"
          v-model="vmodel"
          :mask="propriedadesInput.maskOptions"
          :masked="propriedadesInput.masked"
          @input="onChange"
        />

        <b-form-file
          v-if="propriedadesInput.isInputFile"
          v-model="vmodel"
          :class="`form-control ${propriedadesInput.class}`"
          dark
          @input="onChange"
          :browse-text="propriedadesInput.inputFileBrowseText"
          :placeholder="propriedadesInput.placeholder"
        ></b-form-file>

        <b-form-datepicker
          v-if="propriedadesInput.isDatePicker"
          v-model="vmodel"
          @input="onChange"
          locale="pt"
          :class="`form-control ${propriedadesInput.class}`"
          label-help=""
          label-no-date-selected=""
        ></b-form-datepicker>

        <b-input-group-append v-if="propriedadesInput.appendButton">
          <b-button
            class="border-0"
            @click="executarAcaoBotaoInput()"
            variant="outline-secondary"
          >
            <b v-if="labelAppendButton">{{
              propriedadesInput.labelAppendButton
            }}</b>
            <b-icon
              v-if="propriedadesInput.iconAppendButton"
              font-scale="1.4"
              :icon="propriedadesInput.iconAppendButton"
              variant="primary"
            ></b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div v-if="isSwitch" class="text-left mb-3 mt-3">
      <b-form-checkbox
        @change="onChange"
        v-model="vmodel"
        :disabled="propriedadesInput.disabled"
        switch
      >
        <h6 class="text-white">
          {{ propriedadesInput.label }}
          <Hint
            v-if="propriedadesInput.hintText"
            containerClass="d-contents"
            :hintText="propriedadesInput.hintText"
            :hintTitle="propriedadesInput.hintTitle"
            iconName="info-circle"
            :variant="'info'"
          />
        </h6>
      </b-form-checkbox>
    </div>

    <multiselect
      :class="`form-control ${propriedadesInput.class}`"
      v-if="propriedadesInput.isMultiselect"
      ref="multiselectCustom"
      size="sm"
      v-model="vmodel"
      :options="[]"
      :label="propriedadesInput.labelsMultiselect"
      :placeholder="propriedadesInput.placeholder"
      selectLabel="Selecione"
      selectedLabel="Selecionado"
      deselectLabel="Remover seleção"
      tag-placeholder=""
      :multiple="propriedadesInput.multiple"
      :trackBy="propriedadesInput.labelsMultiselect"
      @open="exibirModalMultiselect(true)"
      preventAutofocus
      :allowEmpty="false"
      :searchable="false"
      :close-on-select="true"
      :clearOnSelect="propriedadesInput.clearOnSelect"
      :resetAfter="propriedadesInput.resetAfter"
      :disabled="propriedadesInput.disabled"
      @remove="removerOpcaoSelecionadaMultiselect"
    >
      <template v-slot:noOptions>Sem registros</template>
      <template v-slot:noResult>Nenhum registro encontrado</template>
    </multiselect>
    <!-- </FloatingLabel> -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Mixin from "../core/Mixin";
import api from "../http/index";

export default {
  name: "CustomInput",
  mixins: [Mixin],
  components: {
    Multiselect,
  },
  data() {
    return {
      vmodel: undefined,
      state: undefined,
      fields: [],
      travaModal: false,
      filtroBuscaMultiselect: "",
      opcoesMultiselect: [],
    };
  },
  props: {
    inputClass: {
      type: String,
      default: "form-control",
    },
    hintText: {
      type: String,
    },
    hintTitle: {
      type: String,
      default: "Informação",
    },
    isMultiselect: {
      type: Boolean,
      default: false,
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    isInputFile: {
      type: Boolean,
      default: false,
    },
    isInputMask: {
      type: Boolean,
      default: false,
    },
    isInputCurrencyMask: {
      type: Boolean,
      default: false,
    },
    isDatePicker: {
      type: Boolean,
      default: false,
    },
    isTextArea: {
      type: Boolean,
      default: false,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    maskOptions: {
      type: Array,
      default: () => {
        return [""];
      },
    },
    currencyMaskOptions: {
      type: Object,
      default: () => {
        return {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          suffix: "",
          precision: 2,
          masked: false,
        };
      },
    },
    appendButton: {
      type: Boolean,
      default: false,
    },
    inputFileBrowseText: {
      type: String,
      default: "Buscar",
    },
    scriptButtonGroup: {
      type: String,
      default: "",
    },
    executeOnPressButtonGroup: {
      type: String,
      default: "",
    },
    iconAppendButton: {
      type: String,
      default: "",
    },
    labelAppendButton: {
      type: String,
      default: "",
    },
    axiosPathData: {
      type: String,
      default: "",
    },
    axiosKeyFilter: {
      type: String,
      default: "search",
    },
    optionsAxios: {
      type: Object,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    placeholder: {
      type: String,
    },
    valid: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselectOptions: {
      required: false,
      // default:[]
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    formatter: {
      type: Function,
    },
    labelsMultiselect: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    resetAfter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return this.id;
    },

    propriedadesInput() {
      let propriedades = {
        isMultiselect: this.lodash.cloneDeep(this.isMultiselect),
        isSwitch: this.lodash.cloneDeep(this.isSwitch),
        isInputFile: this.lodash.cloneDeep(this.isInputFile),
        isInputMask: this.lodash.cloneDeep(this.isInputMask),
        isInputCurrencyMask: this.lodash.cloneDeep(this.isInputCurrencyMask),
        masked: this.lodash.cloneDeep(this.masked),
        maskOptions: this.lodash.cloneDeep(this.maskOptions),
        currencyMaskOptions: this.lodash.cloneDeep(this.currencyMaskOptions),
        appendButton: this.lodash.cloneDeep(this.appendButton),
        inputFileBrowseText: this.lodash.cloneDeep(this.inputFileBrowseText),
        scriptButtonGroup: this.lodash.cloneDeep(this.scriptButtonGroup),
        executeOnPressButtonGroup: this.lodash.cloneDeep(
          this.executeOnPressButtonGroup
        ),
        iconAppendButton: this.lodash.cloneDeep(this.iconAppendButton),
        labelAppendButton: this.lodash.cloneDeep(this.labelAppendButton),
        axiosPathData: this.lodash.cloneDeep(this.axiosPathData),
        axiosKeyFilter: this.lodash.cloneDeep(this.axiosKeyFilter),
        optionsAxios: this.lodash.cloneDeep(this.optionsAxios),
        multiple: this.lodash.cloneDeep(this.multiple),
        label: this.lodash.cloneDeep(this.label),
        modelValue: this.lodash.cloneDeep(this.modelValue),
        placeholder: this.lodash.cloneDeep(this.placeholder),
        valid: this.lodash.cloneDeep(this.valid),
        disabled: this.lodash.cloneDeep(this.disabled),
        multiselectOptions: this.lodash.cloneDeep(this.multiselectOptions),
        visible: this.lodash.cloneDeep(this.visible),
        formatter: this.lodash.cloneDeep(this.formatter),
        labelsMultiselect: this.lodash.cloneDeep(this.labelsMultiselect),
        inputType: this.lodash.cloneDeep(this.inputType),
        clearOnSelect: this.lodash.cloneDeep(this.clearOnSelect),
        resetAfter: this.lodash.cloneDeep(this.resetAfter),
        class: this.lodash.cloneDeep(this.inputClass),
        hintText: this.lodash.cloneDeep(this.hintText),
        hintTitle: this.lodash.cloneDeep(this.hintTitle),
        isDatePicker: this.lodash.cloneDeep(this.isDatePicker),
        isTextArea: this.lodash.cloneDeep(this.isTextArea),

        ...this.lodash.cloneDeep(
          this.lodash.get(this.$options, "propsData", {})
        ),
      };

      return propriedades;
    },
  },

  mounted() {
    if (this.modelValue) {
      this.popularValorCampo();
    }

    if (this.formatter) {
      this.executarFormatador();
    }

    this.obterCabecalhosTabela();
    this.obterOpcoesMultiselect();
  },

  methods: {
    obterMascara() {
      return this.lodash.cloneDeep(this.maskOptions); // Usando lodash para criar uma cópia profunda
    },

    removerOpcaoSelecionadaMultiselect(removedOption, id) {
      if (this.$refs["multiselectCustom"].$options.propsData.multiple) {
        let arrayItens = this.$refs["multiselectCustom"].getValue();

        arrayItens = arrayItens.filter((item) => item.id != removedOption.id);

        this.onChange(arrayItens);
      } else {
        this.onChange(undefined);
      }
    },
    async executarFiltroMultiselect(filtro) {
      if (await this.debounce(300)) {
        this.obterOpcoesMultiselect(filtro);
      }
    },

    async obterOpcoesMultiselect(filtro) {
      try {
        if (!this.optionsAxios) {
          let options = this.multiselectOptions;

          if (filtro && filtro != "") {
            options = this.multiselectOptions.filter((localOption) =>
              localOption[this.labelsMultiselect]
                .toLowerCase()
                .includes(filtro.toLowerCase())
            );
          }

          this.atualizarOpcoesTabelaMultiselect(options);
          return;
        }

        this.setLoading(true);

        this.optionsAxios.params = {
          ...this.optionsAxios.params,
          [this.axiosKeyFilter]: filtro,
        };

        let retorno = await api(this.optionsAxios);

        this.atualizarOpcoesTabelaMultiselect(
          this.lodash.get(retorno, this.axiosPathData, [])
        );
      } catch (e) {
        console.warn(`[obterOpcoesMultiselect] Detalhes: ${e}`);
        this.atualizarOpcoesTabelaMultiselect([]);
      } finally {
        this.setLoading(false);
      }
    },

    atualizarOpcoesTabelaMultiselect(opcoes) {
      this.opcoesMultiselect = opcoes;

      if (!this.$refs.tableOptionsMultiselect) return;

      this.$refs.tableOptionsMultiselect.refresh();
    },

    executarAcaoBotaoInput() {
      if (this.scriptButtonGroup != "") {
        eval(this.scriptButtonGroup);
      }

      if (this.executeOnPressButtonGroup != "") {
        this.$emit(
          "input-group-button-pressed",
          this.executeOnPressButtonGroup,
          this.vmodel
        );
      }
    },

    popularValorCampo() {
      if (this.isSwitch && typeof this.modelValue === "string") {
        this.vmodel = this.modelValue.toLocaleLowerCase() === "true";
      }

      this.vmodel = this.modelValue;
    },

    executarFormatador() {
      try {
        if (this.formatter()) {
          this.formatter = this.formatter;
        }
      } catch (_) {
        this.formatter = eval(this.formatter);
      }
    },

    selecionarLinha(item) {
      if (this.$refs["multiselectCustom"].$options.propsData.multiple) {
        let arrayItens = this.$refs["multiselectCustom"].getValue();
        arrayItens.push(item);
        this.$refs["multiselectCustom"].select(arrayItens);
        this.onChange(arrayItens);
      } else {
        this.$refs["multiselectCustom"].select(item);
        this.onChange(item);
      }

      this.$nextTick(() => {
        this.fecharModal();
      });
    },

    obterCabecalhosTabela() {
      // to do:
      this.fields = [
        {
          key: this.labelsMultiselect,
          label: "",
          thClass: "text-center",
          tdClass: "text-center cursor-pointer",
        },
      ];
    },

    exibirModalMultiselect(modo) {
      this.$nextTick(() => {
        if (modo && !this.travaModal) {
          this.$refs["modal-dinamico"].show();
        } else {
          // this.$refs["modal-dinamico"].hide();
        }
      });
    },

    fecharModal(modo) {
      this.travaModal = true;
      this.$refs["modal-dinamico"].hide();

      setTimeout(() => {
        this.travaModal = false;
      }, 500);
    },

    onChange(value) {
      let parsedValue = value;

      if (!isNaN(parsedValue) && typeof parsedValue != "boolean") {
        parsedValue = parseFloat(value);
      }

      this.$emit("change-value", { [this.name]: parsedValue });
    },
  },
};
</script>
<style>
.container-detalhes-modal-options {
  /* border-bottom: 2px dotted rgb(102, 102, 102); */
  padding: 10px; /* Espaçamento interno */
  background-color: var(--cor-fundo) !important;
}

.form-group {
  margin: 0 !important;
}

.modal-content {
  border-color: var(--cor-cards) !important;
  border-radius: 0 !important;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
  border-color: var(--cor-cards) !important;
  border-radius: 0 !important;
}

.modal-title {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}
.customInput {
  /* margin-top:15px; */
  /* padding:0 */
  /* height: 200px !important; */
}

.customInput:focus {
  outline: none !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: 0 0 0;
  outline: 0 none;
}

.multiselect__select {
  padding-top: 25px !important;
}
.customInput:focus,
.multiselect:focus {
  outline: none !important;
}

.customInput,
.multiselect {
  border: none !important;
  background-color: transparent;
  padding-top: 0 !important;
}

fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: var(--cor-principal);
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  /* width: calc(100% - 72px); */
  width: calc(100%);
  /* min-height:37px; */
  height: 23px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: transparent;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  margin: 0 !important;
  min-height: 38px;
  display: block;
  padding: 14px !important;
  /* padding-top: 0px !important; */
  border-radius: 5px;
  /* border:1px solid #e8e8e8; */
  background: transparent;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: transparent;
  line-height: 1;
  background: var(--cor-principal);
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  color: black;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: transparent;
}
.multiselect__current {
  min-height: 38px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  /* width:40px; */
  /* height:38px; */
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  /*display:inline-block;*/
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 38px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 38px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: var(--cor-principal);
  outline: none;
  /* color:#fff' */
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: var(--cor-principal);
  /* color:#fff */
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: var(--cor-principal-claro);
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: var(--cor-principal-claro);
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: var(--cor-principal-claro);
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: var(--cor-principal-claro);
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
.emptySelect .multiselect__content li:last-child {
  display: block !important;
}
[dir="rtl"] .multiselect {
  text-align: right;
}
[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 38px;
}
[dir="rtl"] .multiselect__content {
  text-align: right;
}
[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}

@media (max-width: 768px) {
  .customInput,
  .multiselect {
    border: none !important;
    background-color: transparent;
    padding-top: 8vw;
  }
}

@media (max-width: 992px) {
  .customInput,
  .multiselect {
    border: none !important;
    background-color: transparent;
    padding-top: 4vw;
  }
}

@media (min-width: 993px) {
  .customInput,
  .multiselect {
    border: none !important;
    background-color: transparent;
    padding-top: 2vw;
  }
}

@media (min-width: 1920px) {
  .customInput,
  .multiselect {
    border: none !important;
    background-color: transparent;
    padding-top: 1vw;
  }
}

.multiselect__option {
  display: none;
}
</style>
