<template>
  <b-row>
    <b-col
      v-if="itens && itens.length === 0"
      class="d-flex align-items-center justify-content-center mt-5 no-services-found"
    >
      <p class="text-muted card-subtitle">{{ textoSemItens }}</p>
    </b-col>

    <b-col
      :cols="cols"
      :xl="colXL"
      :lg="colLG"
      :md="colMD"
      :sm="colSM"
      v-else
      v-for="(item, index) in itens"
      :key="index"
      @click="selected(item)"
    >
      <b-row
        align-v="center"
        :class="`container service-container text-center ${
          item.containerClass ? item.containerClass : containerClass
        }`"
      >
        <div
          style="display: contents"
          v-for="(chave, chaveIndex) in chaves"
          :key="chaveIndex"
        >
          <b-col
            v-show="obterSeExibeLinha(chave.visibleFn, item)"
            :cols="
              lodash.get(chave, 'containerCols', lodash.get(chave, 'cols', 12))
            "
            :sm="lodash.get(chave, 'containerSM', 12)"
            :md="lodash.get(chave, 'containerMD', 12)"
            :lg="lodash.get(chave, 'containerLG', 12)"
            :xl="lodash.get(chave, 'containerXL', 12)"
          >
            <component
              v-if="chave.tipo === 'text'"
              :class="lodash.get(chave, 'class', '')"
              :style="lodash.get(chave, 'style', '')"
              :is="lodash.get(chave, 'tag', 'p')"
              v-bind="chave.customProps"
            >
              <b
                v-if="lodash.get(chave, 'titulo')"
                class="text-muted mb-3 titulo-itens-catalogo mr-1"
                >{{ lodash.get(chave, "titulo") }}</b
              >
              <b-icon
                v-if="lodash.get(chave, 'iconName')"
                :class="lodash.get(chave, 'iconClass', 'mr-2')"
                :font-scale="lodash.get(chave, 'iconFontScale', 1.4)"
                :variant="lodash.get(chave, 'iconVariant', 'light')"
                :icon="lodash.get(chave, 'iconName')"
              ></b-icon>

              {{ obterValorTexto({ chave, item }) }}
            </component>

            <component
              v-if="chave.tipo === 'icon'"
              :style="lodash.get(chave, 'style', '')"
              :is="lodash.get(chave, 'tag', 'i')"
              :class="lodash.get(item, chave.chave, '')"
              v-bind="chave.customProps"
            >
            </component>

            <component
              v-if="chave.tipo === 'custom'"
              :class="lodash.get(chave, 'class', '')"
              :style="lodash.get(chave, 'style', '')"
              :is="lodash.get(chave, 'tag', 'p')"
              :src="lodash.get(item, chave.chave, '')"
              :chartData="
                lodash.get(chave, 'tag', '') == 'Chart'
                  ? lodash.get(item, chave.chave, '')
                  : []
              "
              :itens="
                lodash.get(chave, 'tag', '') == 'Catalog'
                  ? lodash.get(item, chave.chave, '')
                  : []
              "
              @button-click="
                buttonPressed(item, lodash.get(chave, 'chave', ''))
              "
              v-bind="chave.customProps"
            >
            </component>
          </b-col>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import lodash from "lodash";
import Mixin from "../core/Mixin";

export default {
  name: "Catalog",
  mixins: [Mixin],
  props: {
    itens: {
      type: Array,
      required: true,
    },
    chaves: {
      type: Array,
      required: true,
    },
    colXL: {
      type: Number,
      required: false,
    },
    colLG: {
      type: Number,
      required: false,
    },
    colMD: {
      type: Number,
      required: false,
    },
    colSM: {
      type: Number,
      required: false,
    },
    cols: {
      type: Number,
      required: false,
    },
    containerClass: {
      type: String,
      required: false,
    },
    textoSemItens: {
      type: String,
      default: "Não há itens para exibir",
    },
  },
  methods: {
    obterSeExibeLinha(fn, item) {
      if (fn) {
        try {
          return eval(fn);
        } catch (e) {
          return true;
        }
      } else {
        return true;
      }

      // return false;

      // return true; // Retorna true se fn não for uma função
    },

    selected(selecionado) {
      this.$emit("selected", selecionado);
    },

    buttonPressed(selecionado, idBotao) {
      this.$emit("buttonPressed", selecionado, idBotao);
    },

    obterValorTexto({ chave, item }) {
      try {
        let valorChave = lodash.get(item, chave.chave, "");

        if (chave.converterParaFloat) {
          valorChave = parseFloat(valorChave);
        }

        if (chave.formatter) {
          valorChave = chave.formatter(valorChave);
        }

        return valorChave;
      } catch (erro) {
        console.error(
          `Houve uma falha ao executar o método [obterValorTexto] em [Catalog]. Detalhes: ${erro} | Chave: ${JSON.stringify(
            chave
          )} `
        );
        return "";
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 992px) {
  .service-container {
    min-height: 35vh;
    padding: 2px;
  }
}

.main-container {
  background-color: #fff;
  padding: 30px;
  cursor: pointer;
}

.container {
  margin-top: 20px;
  cursor: pointer;
  margin-left: 3px;
  padding: 10px;
}

.border-bottom-large {
  border-bottom: lightgray 4px solid;
}

.bordas-claras {
  border: lightgray 4px solid;
}

.no-services-found {
  font-size: 21px;
  color: #c2c2c2 !important;
}

.help-new-service {
  cursor: pointer;
}
</style>
