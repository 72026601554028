<template>
  <b-row :key="componentKey" :class="`p-2 ${rowContainerClass}`">
    <b-col
      v-for="(button, index) in buttons"
      :key="index"
      style="display: contents"
    >
      <CustomButton
        v-bind="button"
        @button-click="pressionarBotao(button.click)"
      />
    </b-col>
  </b-row>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "GroupButton",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      buttons: [],
      componentKey: 1,
    };
  },
  props: {
    opcoes: {
      type: Array,
      default: [],
    },
    rowContainerClass: {
      type: String,
      default: "",
    },
  },

  mounted() {
    this.montarEstruturaGrupoBotoes();
  },

  methods: {
    atualizarComponente() {
      this.componentKey += 1;
    },

    montarEstruturaGrupoBotoes() {
      this.buttons = this.opcoes;

      this.atualizarComponente();
    },

    pressionarBotao(acao) {
      //Local onde recebe o evento, deve ter a acao nos methods.
      this.$emit("group-button-click", acao);
    },
  },
};
</script>
<style>
</style>
