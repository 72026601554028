import api from "../http/index"

class UsuarioServices {

  async uploadImagemUsuario({photo}){
    const formData = new FormData();

    const file = photo;
    formData.append('user[photo]', file);

    let retorno = await api({
      method: "PATCH",
      url: `/current_user/images`,
      data:formData,
    });

    return retorno;
  }
    
    async obterUsuarios(params = { profile_type: 1, active: true }) {
    
    let retorno = await api({
      method: "GET",
      url: `/users?key_transform_camel_lower=true`,
      params: {
        ...params,
      },
    });

    return retorno;
  };

  async obterUsuarioSessao() {
    
    let retorno = await api({
      method: "GET",
      url: `/current_user?key_transform_camel_lower=true`
    });

    return retorno;
  };

  async obterUsuarioPorId(id) {
    
    let retorno = await api({
      method: "GET",
      url: `/users/${id}/?key_transform_camel_lower=true`
    });

    return retorno;
  }

  async gravarNovoUsuario(dados) {
        
    let retorno = await api({
        method: "POST",
        url: "account_admins/users",
        data: {
            user:{
                ...dados
            }
        },
    });

    return retorno;
};

async editarUsuario(dados) {
    
    let retorno = await api({
        method: "PATCH",
        url: `account_admins/users/${dados.id}`,
        data: {
            user:{
                ...dados
            }
            
        },
    });

    return retorno;
};
}
 
export default new UsuarioServices()
