<template>
  <b-modal
    ref="customModal"
    :size="size"
    modal-class="custom-modal"
    :hide-header-close="hideHeaderClose"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :hide-header="hideHeader"
    :hide-footer="hideFooter"
    centered
    :scrollable="scrollable"
    :title="title"
    body-class="container-detalhes-modal-options"
    footer-class="container-detalhes-modal-options"
    header-class="container-detalhes-modal-options"
  >
    <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
      <slot :name="slotName" v-bind="scope" />
    </template>
  </b-modal>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "CustomModal",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      componentKey: 1,
    };
  },
  props: {
    hideHeaderClose: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
    },
  },

  mounted() {},

  methods: {
    atualizarComponente() {
      this.componentKey += 1;
    },

    exibirModal(nomeModal = "customModal") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].show();
      }
    },

    fecharModal(nomeModal = "customModal") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].hide();
      }
    },
  },
};
</script>
<style>
.modal-dialog-scrollable {
  right: 0 !important;
  position: fixed !important;
  height: 100vh !important;
  min-width: 70vw;
}

.custom-modal > .modal-dialog {
  transition: transform 0.5s ease-out !important;
  transform: translate(50%) !important;
}

.modal {
  --bs-modal-margin: 1px !important;
}
</style>
