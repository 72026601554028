<template>
  <div>
    <step-progress
      :steps="steps"
      :current-step="currentStep"
      icon-class="bi-check"
      :active-thickness="2"
      active-color="#4CAF50"
      passive-color="rgb(34, 37, 45)"
      :passive-thickness="2"
      :line-thickness="7"
    ></step-progress>
  </div>
</template>

<script>
import "vue-step-progress/dist/main.css";
import StepProgress from "vue-step-progress";
import Mixin from "../core/Mixin";

export default {
  mixins: [Mixin],
  components: {
    "step-progress": StepProgress,
  },
  props: {
    steps: Array,
    currentStep: Number,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  beforeDestroy() {},
};
</script>

<style>
.step-progress__bar {
  height: auto !important;
}
.step-progress__step-label {
  font-size: 12px !important;
  top: calc(100% + 15px) !important;
}

.step-progress__step span {
  font-size: 20px !important;
  transition: 1s ease !important;
}
.step-progress__step:after {
  height: 30px !important;
  transition: 1s ease !important;
  width: 30px !important;
}
</style>