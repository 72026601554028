import { createConsumer } from "@rails/actioncable";

let dadosUsuarioLogado = JSON.parse(localStorage.getItem("dadosUsuarioLogado"));

if (dadosUsuarioLogado && dadosUsuarioLogado.length > 0) {
  dadosUsuarioLogado = dadosUsuarioLogado[0];
};

const generateUrlWithParams = (baseUrl, params) => {
    const url = new URL(baseUrl);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    return url.toString();
  };
  
  const params = {
    authkey: "f4f49fd6deed76678e60",
    "access-token": dadosUsuarioLogado?.accessToken ?? "",
    client: dadosUsuarioLogado?.client ?? "",
    uid: dadosUsuarioLogado?.uid ?? "",
    plataform: 1,
  };
  
  const customUrl = generateUrlWithParams(process.env.VUE_APP_SOCKET_URL, params);
  
  const consumer = createConsumer(customUrl);
  

export default consumer;