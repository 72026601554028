<template>
  <b-row :class="'d-flex justify-content-center'">
    <CustomTable
      v-if="Object.keys(opcoesTabela).length > 0"
      v-bind="opcoesTabela"
      borderless
      :fields="fields"
      table-variant="light"
      :items="items"
      stacked="lg"
      :dark="true"
      v-on:row-clicked="(row) => selecionarLinha(row.item)"
      :hidePagination="hidePagination"
    >
      <template #cell(file)="row">
        <ImageThumb
          :thumbnail="false"
          :rounded="true"
          :altura="70"
          :largura="70"
          :src="lodash.get(row, 'item.file.small', null)"
        />
      </template>
    </CustomTable>
  </b-row>
</template>


<script>
import Mixin from "../../core/Mixin";
import Utils from "./utils";

export default {
  mixins: [Mixin],
  data: function () {
    return {
      inputs: [],
      items: [],
      fields: [],
      opcoesTabela: {},
    };
  },
  props: {
    hidePagination: Boolean,
  },
  async mounted() {
    this.setLoading(true);

    this.montarCabecalhosTabela();
    await this.montarTabela();

    this.setLoading(false);
  },

  methods: {
    montarTabela() {
      this.opcoesTabela = {
        optionsAxios: {
          method: "GET",
          url: `/products?key_transform_camel_lower=true`,
          params: {
            sort_direction: "asc",
            sort_property: "name",
            count: true,
            per_page: 10,
            active: true,
            page: 1,
          },
        },
        axiosPathData: "data.products",
        axiosKeyFilter: "name",
        axiosPathCount: "data.count",
        itemsPerPage: 10,
      };
    },

    selecionarLinha(linha) {
      this.$emit("selecionado", linha);
    },

    obterCabecalhosTabela() {
      return Utils.obterCabecalhosTabelaListaProdutos();
    },

    async montarCabecalhosTabela() {
      this.fields = this.obterCabecalhosTabela();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>